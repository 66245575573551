import IconCampaign from '@/assets/icons/icon-campaign.svg?component';
import IconKokkiKlubi from '@/assets/icons/icon-kokkiklubi.svg?component';
import Badge from '@/components/Badge';
import { useCustomerCurrency } from '@/composables/useCustomerCurrency';
import type { CampaignLink } from '@ruokaboksi/api-client';
import { Week, getLoyaltyDiscountPercentage } from '@ruokaboksi/utils';
import type { DeliveryWeekWithFormattedDays } from 'models';
import type { VNode } from 'vue';

const calculateDiscount = (
  currentWeek: Week,
  campaignStartWeek: Week,
  linkedCampaign: Ref<CampaignLink | undefined | null>
) => {
  const diff = currentWeek.differenceWeeks(campaignStartWeek);
  if (
    linkedCampaign.value?.campaign.campaignType === 'additionalProductDiscount'
  ) {
    return 0;
  }
  return linkedCampaign.value?.campaign.discounts[diff] || 0;
};

export default defineComponent({
  name: 'WeekDiscountBadge',
  props: {
    deliveryWeek: {
      required: true,
      type: Object as PropType<DeliveryWeekWithFormattedDays>,
    },
    size: {
      default: 'normal',
      type: String as PropType<'normal' | 'mini'>,
    },
  },
  setup(props) {
    const { currentCustomer } = useCurrentCustomer();
    const { subscription } = useCustomerSubscriptions();
    const { getLinkedSubscriptionCampaign } = useCampaignsApi();
    const { formatPrice } = useCustomerCurrency();

    const subscriptionId = computed(() => subscription.value?.id);

    const { data: linkedCampaign } =
      getLinkedSubscriptionCampaign(subscriptionId);

    // Check whether the campaign is still active by comparing the current deliveryWeek to startWeek. Week class has method add weeks, and we should also check whether it goes into that range
    const activeCampaign = computed(() => {
      const duration = linkedCampaign.value?.campaign.durationWeeks;
      const startIsoWeek = linkedCampaign.value?.startIsoWeek;
      const startIsoYear = linkedCampaign.value?.startIsoYear;

      if (
        !startIsoWeek ||
        !startIsoYear ||
        subscription.value?.defaultDeliverySlot === undefined
      ) {
        return null;
      }

      const startWeek = new Week(
        startIsoYear,
        startIsoWeek,
        subscription.value?.defaultDeliverySlot
      );

      const deliveryWeek = Week.fromYyyyWww(
        props.deliveryWeek.weekString,
        subscription.value.defaultDeliverySlot
      );

      if (deliveryWeek.compare(startWeek) === 0) {
        return {
          discount: calculateDiscount(deliveryWeek, startWeek, linkedCampaign),
        };
      }

      if (
        deliveryWeek.compare(startWeek) === 1 &&
        deliveryWeek.compare(startWeek.withAddWeeks(duration || 0)) === -1
      ) {
        return {
          discount: calculateDiscount(deliveryWeek, startWeek, linkedCampaign),
        };
      }

      return null;
    });

    const isLoyaltyActive = computed(() => {
      return (
        currentCustomer.value?.inLoyaltyProgram &&
        props.deliveryWeek.loyaltyLevel > 1
      );
    });

    const badgeData = computed(() => {
      if (activeCampaign.value) {
        return {
          icon: () => <IconCampaign class="icon" />,
          children: `-${formatPrice(activeCampaign.value.discount)}`,
          type: props.deliveryWeek.paused ? 'caution' : 'success',
        } as const;
      } else if (isLoyaltyActive.value) {
        if (props.size === 'mini') {
          return {
            icon: () =>
              Array.from(
                { length: props.deliveryWeek.loyaltyLevel },
                (_, index) => <IconKokkiKlubi key={index} class="icon" />
              ),
            children: null,
            type: 'loyalty',
          } as const;
        }

        return {
          icon: () => <IconKokkiKlubi class="icon" />,
          children: `-${getLoyaltyDiscountPercentage(
            props.deliveryWeek.loyaltyLevel
          )}%`,
          type: 'loyalty',
        } as const;
      } else {
        return null;
      }
    });

    return {
      badgeData,
    };
  },
  render(): VNode | null {
    if (this.badgeData) {
      return (
        <Badge
          v-slots={{ icon: this.badgeData.icon }}
          type={this.badgeData.type}
          size={this.size}
        >
          {this.badgeData.children}
        </Badge>
      );
    } else {
      return null;
    }
  },
});
